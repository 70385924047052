* {
  scrollbar-width: thin;
  scrollbar-color: #e8c189 #2b2b2b;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: #2b2b2b;
}
*::-webkit-scrollbar-thumb {
  background-color: #e8c189;
  border-radius: 20px;
  border: 3px solid #2b2b2b;
}

.scroll-to-top {
  position: fixed;
  top: 1rem;
  right: 0;
  width: 5rem;
  align-items: center;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

@font-face {
  font-family: ffCooperBlk;
  src: url(../vendor/CooperBlackRegular.ttf);
}
