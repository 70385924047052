// Spacing columns vertically

.row.row-grid > [class*="col-"] + [class*="col-"] {
    margin-top: 3rem;
}

@include media-breakpoint-up(lg) {
    .row.row-grid > [class*="col-lg-"] + [class*="col-lg-"] {
        margin-top: 0;
    }
}
@include media-breakpoint-up(md) {
    .row.row-grid > [class*="col-md-"] + [class*="col-md-"] {
        margin-top: 0;
    }
}
@include media-breakpoint-up(sm) {
    .row.row-grid > [class*="col-sm-"] + [class*="col-sm-"] {
        margin-top: 0;
    }
}

.row-grid + .row-grid {
    margin-top: 3rem;
}

// Negative margins and paddings

.mt-10 {
    margin-top: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

// Large negative margins in pixels

.mt--100 {
    margin-top: -100px !important;
}
.mr--100 {
    margin-right: -100px !important;
}
.mb--100 {
    margin-bottom: -100px !important;
}
.ml--100 {
    margin-left: -100px !important;
}
.mt--150 {
    margin-top: -150px !important;
}
.mb--150 {
    margin-bottom: -150px !important;
}
.mt--200 {
    margin-top: -200px !important;
}
.mb--200 {
    margin-bottom: -200px !important;
}
.mt--300 {
    margin-top: -300px !important;
}
.mb--300 {
    margin-bottom: -300px !important;
}
.pt--5p {
    padding-top: -5%!important;
}
.pb--5p {
    padding-bottom: -5%!important;
}
.ml-10p {
    margin-left: 10%!important;
}
.mr-10p {
    margin-right: 10%!important;
}
.ml-5p {
    margin-left: 5%!important;
}
.mr-5p {
    margin-right: 5%!important;
}
.ml--5p {
    margin-left: -5%!important;
}
.mr--5p {
    margin-right: -5%!important;
}


// Large margins in pixels

.pt-100 {
    padding-top: 100px !important;
}
.pb-100 {
    padding-bottom: 100px !important;
}
.pt-150 {
    padding-top: 150px !important;
}
.pb-150 {
    padding-bottom: 150px !important;
}
.pt-200 {
    padding-top: 200px !important;
}
.pb-200 {
    padding-bottom: 200px !important;
}
.pt-250 {
    padding-top: 250px !important;
}
.pb-250 {
    padding-bottom: 250px !important;
}
.pt-300 {
    padding-top: 300px!important;
}
.pb-300 {
    padding-bottom: 300px!important;
}
.pt-12p {
    padding-top: 12%!important;
}
.pb-12p {
    padding-bottom: 12%!important;
}
.pt-15p {
    padding-top: 15%!important;
}
.pt-15vh {
    padding-top: 15vh!important;
}
.pb-15p {
    padding-bottom: 15%!important;
}
.pt-5p {
    padding-top: 5%!important;
}
.pb-5p {
    padding-bottom: 5%!important;
}
.pt-50p {
    padding-top: 50%!important;
}
.pb-50p {
    padding-bottom: 50%!important;
}

.right {
    width: 100%;
}
