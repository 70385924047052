.landing-section {
  background-image: url("../img/Landing-BG-sm.jpg");

  height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover; /* make a "best guess" here as a default */
  background-attachment: fixed;
}

.gallery-section {
  background-image: url("../img/Gallery/Gallery-BG.jpg");

  height: 1000px;

  background-position: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%; /* make a "best guess" here as a default */
  background-attachment: fixed;
}

.landing-logo {
  padding-top: 8vh !important;
  width: 30vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 992.5px) {
  .landing-section {
    background: url("../img/Landing-BG-mobile.jpg");

    background-position: center center;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover; /* make a "best guess" here as a default */
    background-attachment: scroll;
  }

  .landing-logo {
    padding-top: 1vh !important;
    width: 20vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1450px) {
  .landing-section {
    background: url("../img/Landing-BG.jpg");
    height: 100vh;

    background-position: center;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover; /* make a "best guess" here as a default */
    background-attachment: fixed;
  }

  .landing-logo {
    padding-top: 12vh !important;
    width: 30vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-height: 635px) {
  .landing-section {
    height: 100%;
  }
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.fade-in {
  position: fixed;
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
