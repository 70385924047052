// Height values in vh

.h-100vh {
  height: 100vh !important;
}

.h-50 {
  height: 50vh !important;
}

.w-60vw {
  width: 60vw !important;
}

.w-30vw {
  width: 30vw !important;
}
